import React from 'react';

function ChevronDown(props) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="icon icon-tabler icon-tabler-chevron-down" 
            width="24" height="24" 
            viewBox="0 0 24 24" 
            stroke-width="2" 
            stroke="currentColor" 
            fill="none" 
            stroke-linecap="round" 
            stroke-linejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M6 9l6 6l6 -6" />
        </svg>
    );
}

export default ChevronDown;